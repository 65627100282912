export const SIZES = {
  eight: 8,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  sixteen: 16,
  eighteen: 18,
  twenty: 20,
  twentyTwo: 22,
  twentyFour: 24,
  twentyEight: 28,
  thirty: 30,
  thirtyTwo: 32,
  thirtyFour: 34,
  thirtyEight: 38,
  fourtyTwo: 42,
  fourtyEight: 48,
  fiftySix: 56,
};

export const FONT_FAMILY = {
  ralewayFontText: 'Raleway',
  montserratFontText: 'Montserrat',
  opensansFontText: 'Open Sans',
  robotoFontText: 'Roboto',
  nunitoFontText: 'Nunito',
};

export const FONT_WEIGHT = {
  light_threeHundred: 300,
  normal_fourHundred: 400,
  medium_fiveHundred: 500,
  semibold_sixHundred: 600,
  bold_sevenHundred: 700,
  extrabold_eightHundred: 800,
};

const PRIMARY_COLORS = {
  teal: '#21AB91',
  darkBlue: '#262A60',
  white: '#fff',
  black: '#000',
};

const SECONDARY_COLORS = {
  darkTeal: '#057275',
  tealTransparent: 'rgba(33, 171, 145, 0.15)',
  violet: '#4E3581',
  lightTeal: '#18D6B2',
  lightGreen: '#29CE7F',
  lightGrayTransparent: 'rgba(108, 125, 139, 0.7)',
  lightGrayTransparentZeroTwentyFive: 'rgba(210, 220, 233, 0.25)',
  lightGrayTransparentZeroFour: 'rgba(210, 220, 233, 0.4)',
  lightGray: '#D2DCE9',
  darkGray: '#6C7D8B',
  darkGrayTransparent: 'rgba(127, 127, 127, 0.3)',
  darkGrayTransparentZeroSix: 'rgba(108, 125, 139, 0.6)',
  darkGrayTransparentZeroTwentyFive: 'rgba(108, 125, 139, 0.25)',
  mediumGray: '#F4F4F4',
  almostBlack: '#1B3040',
  red: '#F04259',
  linkBlue: '#0062E2',
  bgWhite: '#F8F8F8',
  bgWashedWhite: '#F4F4F4',
  transparentBlack: 'rgba(0,0,0, 0.15)',
  lightYellow: '#EBDF71',
  blue: '#1975FF',
};

export const COLORS = {
  ...PRIMARY_COLORS,
  ...SECONDARY_COLORS,
};
