import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Alert from 'components/Alert';

import store, { persistor } from 'store/createStore';
import Router from 'routes';

import theme from 'theme/theme';
import './App.css';

const App = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Alert />
            <div className="App">
              <Router />
            </div>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
};

export default App;
