import { Box, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { useNavigate } from 'react-router-dom';

import { translate } from 'translations/i18n';
import { AlertType } from 'appTypes';
import { setAlert } from 'store/alert/actions';
import AuthenticatorInput from 'components/AuthenticatorInput';
import Button from 'components/Button/CustomButton';
import { getCurrentUser, getLoginAttemptToken } from 'store/auth/selectors';
import { validate2FALogin } from 'store/auth/actions';

import useStyles from '../Onboarding.styles';

const TwoFactorAuthentication = () => {
  //state
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  //hooks
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const username = useSelector(getCurrentUser)?.username;
  const loginAttemptToken = useSelector(getLoginAttemptToken);
  const navigate = useNavigate();

  // methods/functions
  const submitAuthCode = async () => {
    if (!code) {
      return;
    }
    try {
      setLoading(true);
      await dispatch(validate2FALogin(username!, code, loginAttemptToken));
      navigate('/');
    } catch (error: any) {
      if (error?.status === 401 || error?.response?.status === 401) {
        dispatch(setAlert('Invalid Code', AlertType.WARNING));
      } else {
        dispatch(setAlert('Error', AlertType.WARNING));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    submitAuthCode();
    // eslint-disable-next-line
  }, [code]);

  return (
    <>
      <div className={classes.paperHeading}>
        <Typography variant="h4" className={classes.title}>
          {translate('onboarding.codeTitle')}
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          {translate('onboarding.codeSubtitle')}
        </Typography>
      </div>
      <Box className={classes.loginFormFields}>
        <AuthenticatorInput onCompleted={setCode} />
      </Box>
      <Button
        text={translate('onboarding.continue')}
        variant="primary"
        size="large"
        onClick={submitAuthCode}
        fullWidth
        disabled={!code || loading}
      />
    </>
  );
};

export default TwoFactorAuthentication;
