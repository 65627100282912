import { client, Endpoints, postRequest } from './api';

export const validateLogin = async (username: string, code: string, loginAttemptToken: string) => {
  const resp = await client.post(Endpoints.VALIDATE_2FA_LOGIN, {
    code,
    username,
    loginAttemptToken,
  });

  return resp;
};

export const validateCode = async (username: string, code: string) => {
  const resp = await postRequest(Endpoints.VALIDATE_2FA_CODE, {
    username,
    code,
  });

  return resp;
};
