import React, { useMemo, useState } from 'react';
import { IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { SearchOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

import useStyles from './Input.styles';
import { InputProps } from 'appTypes';

const Input = (props: InputProps) => {
  //props
  const {
    errorMsg,
    disabled,
    label,
    variant,
    InputProps,
    renderHelperTextHidden,
    children,
    inputProps,
    type,
    decimalscale,
    placeholder,
    onChange,
    value,
    eyeAdornment,
    showCheck,
    searchIcon,
    jsxElement,
    ...rest
  } = props;

  //state
  const [showPassword, setShowPassword] = useState(false);

  //hooks
  const classes = useStyles();

  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const hasError = useMemo(() => !!errorMsg, [errorMsg]);

  const adornmentProps = InputProps || {
    endAdornment: (
      <InputAdornment position="end">
        {type === 'password' && eyeAdornment && (
          <IconButton
            size="small"
            aria-label="toggle password visibility"
            onClick={handleChangeShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            className={`${showCheck && classes.showPasswordIcon}`}
          >
            {showPassword ? (
              <Visibility className={classes.visibilityColor} />
            ) : (
              <VisibilityOff className={classes.visibilityColor} />
            )}
          </IconButton>
        )}
        {jsxElement}
        {!!searchIcon && <SearchOutlined />}
      </InputAdornment>
    ),
  };

  return (
    <>
      {label && (
        <Typography variant="h5" className={classes.labelStyle}>
          {label}
        </Typography>
      )}
      <TextField
        onChange={onChange}
        className={classes.textFied}
        placeholder={placeholder}
        error={hasError}
        type={showPassword ? 'text' : type}
        disabled={disabled}
        helperText={errorMsg ? errorMsg : renderHelperTextHidden ? '' : ''}
        variant={variant}
        inputProps={{
          className: classes.input,
          ...inputProps,
          decimalscale,
        }}
        value={value}
        autoComplete="on"
        InputProps={{ ...adornmentProps, className: classes.whiteBackground }}
        {...rest}
      >
        {children}
      </TextField>
    </>
  );
};

Input.defaultProps = {
  eyeAdornment: true,
  showCheck: false,
  type: 'text',
  decimalscale: 2,
  renderHelperTextHidden: true,
  variant: 'outlined',
};

export default Input;
