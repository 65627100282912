import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

import Onboarding from 'features/Onboarding';
import Layout from 'components/Layout';
import RouteProtection from './helpers/RouteProtection';
import { routes } from './routes';
import { paths } from 'routes/paths';
import { EqRouteType } from 'appTypes';

const NotFoundPage = lazy(() => import('./helpers/NotFoundPage'));

const RouteWithOutlet = (route: EqRouteType) => {
  return (
    <>
      {route?.element}
      {!!route?.nestedRoutes?.length && <Outlet />}
    </>
  );
};
const Router = () => {
  const renderRoute = (route: EqRouteType, index: number) => {
    return (
      <Route
        key={index}
        {...route}
        element={
          route.private ? (
            <RouteProtection {...route}>
              <RouteWithOutlet {...route} />
            </RouteProtection>
          ) : (
            route.element
          )
        }
      >
        {route?.nestedRoutes?.map((childRoute: any, index: number) => {
          return <Route key={index} path={childRoute?.path} element={childRoute?.element} />;
        })}
      </Route>
    );
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path={paths.LOGIN} element={<Onboarding />} />
          <Route path={paths.ROOT} element={<Layout />}>
            {routes.map(renderRoute)}
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
