import { CircularProgress } from '@material-ui/core';

import { LoadingIndicatorProps } from 'appTypes';

import useStyles from './LoadingIndicator.styles';

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const { loading, size = 24, inline, ...rest } = props;
  const classes = useStyles();
  return (
    <>
      {loading && !inline ? (
        <CircularProgress size={size} {...rest} />
      ) : (
        <CircularProgress className={classes.spinner} {...rest} />
      )}
    </>
  );
};

export default LoadingIndicator;
