import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../createStore';
import { AuthState } from './slice';

const selectAuthState = (state: RootState) => state.auth;

export const selectUser = createSelector(selectAuthState, (state: AuthState) => state.user);

export const userDataSelector = createSelector(
  selectAuthState,
  (state: AuthState) => state.userData,
);

export const selectLoginError = createSelector(
  selectAuthState,
  (state: AuthState) => state.loginError,
);

export const getIsLoading = createSelector(selectAuthState, (authState) => authState.loading);

export const getCodeVerificationNeeded = createSelector(
  selectAuthState,
  (authState) => authState.codeVerificationNeeded,
);
export const getCurrentUser = createSelector(selectAuthState, (authState) => authState.userData);
export const getLoginAttemptToken = createSelector(
  selectAuthState,
  (authState) => authState.loginAttemptToken,
);

export const getIsAuthenticated = createSelector(
  selectAuthState,
  (authState) => authState.isAuthenticated,
);
