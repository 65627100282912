import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Collapse, List, ListItem } from '@material-ui/core';
import { ExpandLessSharp, ExpandMoreSharp } from '@material-ui/icons';

import eqifiLogo from 'assets/images/eqifiBackoffice.svg';

import Link from 'components/Link';
import sidebarLinks from './sidebarLinks';

import useStyles from './Sidebar.styles';

const Sidebar = () => {
  //hooks
  const classes = useStyles();
  const navigate = useNavigate();

  // eslint-disable-next-line
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const pathname = useMemo(() => {
    const { pathname } = location;
    const mainPathname = `/${pathname?.split('/')[1]}`;
    return mainPathname;
  }, [location]);

  const toggleVisibility = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.sidebar}>
      <Box className={classes.imageContainer}>
        <img
          alt="eqifi_logo"
          src={eqifiLogo}
          onClick={() => {
            navigate('/');
          }}
        />
      </Box>
      <div className={classes.itemLinks}>
        {sidebarLinks?.map((link, index) => {
          const isPath = link?.children?.find((route: any) => route.to === pathname) !== undefined;
          return link.children ? (
            <Box
              key={index}
              className={open || isPath ? classes.multiLevelOpen : classes.multiLevel}
            >
              <ListItem button onClick={toggleVisibility} className={classes.listItem}>
                {link?.text}
                {open || isPath ? <ExpandLessSharp /> : <ExpandMoreSharp />}
              </ListItem>
              <Collapse in={open || isPath} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {link?.children.map((child) => (
                    <Link
                      key={child?.to}
                      to={child?.to}
                      className={`${classes.childLinkSidebar} ${
                        pathname === child?.to && 'active'
                      }`}
                    >
                      {child?.text}
                    </Link>
                  ))}
                </List>
              </Collapse>
            </Box>
          ) : (
            <Link
              key={index}
              to={link?.to}
              className={`${classes.linkSidebar} ${pathname === link?.to && 'active'}`}
              onClick={() => setOpen(false)}
            >
              {link?.text}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
