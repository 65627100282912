import { Box, Typography } from '@material-ui/core';

import CodeInput from 'components/CodeInput';
import { translate } from 'translations/i18n';

import { AuthenticatorType } from 'appTypes';

import useStyles from './AuthenticatorInput.styles';

const AuthenticatorInput = (props: AuthenticatorType) => {
  // props
  const { error, onCompleted, autoFocus } = props;

  // hooks
  const classes = useStyles();

  const hasError = !!error;

  return (
    <Box className={classes.container}>
      <Typography variant="h5" className={classes.labelStyle}>
        {translate('onboarding.codeLabel')}
      </Typography>
      <Box className={classes.eqCodeContainer}>
        <CodeInput fields={6} error={hasError} onCodeFilled={onCompleted} autoFocus={autoFocus} />
      </Box>
      {hasError && (
        <div className={classes.errorContainer}>
          <Typography variant="body1" color="error">
            {translate('onboarding.incorrectCode')}
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default AuthenticatorInput;
