import { useEffect, Key } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { RootState } from 'store';
import { Alert } from 'appTypes';
import { removeAlert } from 'store/alert/actions';

let displayed: Array<Key> = [];
const Alerts = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const alertsState = useSelector((state: RootState) => state.alert);

  const storeDisplayed = (id: Key) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: Key) => {
    displayed = [...displayed.filter((key: Key) => id !== key)];
  };

  useEffect(() => {
    alertsState?.length &&
      alertsState?.forEach((alert: Alert) => {
        if (displayed.includes(alert.id)) return;

        enqueueSnackbar(alert.msg, {
          variant: alert.alertType,
          onExited: () => {
            dispatch(removeAlert(alert.id));
            removeDisplayed(alert.id);
          },
        });
        storeDisplayed(alert.id);
      });
  }, [alertsState, dispatch, enqueueSnackbar]);
  return null;
};

export default Alerts;
