import { User } from 'appTypes';

import { Endpoints, getRequest, postRequest } from './api';

const kycLevelMap = {
  None: 'NONE',
  'KYC Level 1': 'LEVEL_1',
  'KYC Level 2': 'LEVEL_2',
  'KYC Level 3': 'LEVEL_3',
};

const bankStepMap = {
  Completed: 'COMPLETED',
  Account: 'ACCOUNT',
  'Basic info': 'BASIC_INFO',
  Card: 'CARDS',
};

export const getUserById = async (userId: string | number) => {
  const resp = await getRequest<User>(`${Endpoints.GET_USER_BY_ID(userId)}`);
  return resp;
};

export const getAllUsers = async (filters: any) => {
  let kycLevel: string[] = [];
  let kycStatus: string[] = [];
  let bankAccountType: string[] = [];
  let bankAccountApplicationStep: string[] = [];
  let country: string[] = [];

  filters?.kycLevel.forEach((filter: string) => {
    let loverCaseLevel: string = kycLevelMap[filter as keyof typeof kycLevelMap];
    kycLevel.push(loverCaseLevel);
  });

  filters?.veriffStatus.forEach((filter: string) => {
    let loverCaseLevel: string = filter.toUpperCase();
    kycStatus.push(loverCaseLevel);
  });

  filters?.type.forEach((filter: string) => {
    let uppercaseFilter: string = filter.toUpperCase();
    bankAccountType.push(uppercaseFilter);
  });

  filters?.bankStatus.forEach((filter: string) => {
    let uppercaseFilter: string = bankStepMap[filter as keyof typeof bankStepMap];
    bankAccountApplicationStep.push(uppercaseFilter);
  });

  filters?.country.forEach((filter: string) => {
    let uppercaseFilter: string = filter;
    country.push(uppercaseFilter);
  });

  const resp = await postRequest<any>(Endpoints.USERS_LIST, {
    name: filters.nameQuery,
    kycLevel: kycLevel,
    kycStatus: kycStatus,
    bankAccountType: bankAccountType,
    bankAccountApplicationStep: bankAccountApplicationStep,
    country: country,
    pagination: {
      page: 0,
      pageSize: 400,
    },
  });

  return resp.content;
};
