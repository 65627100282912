import { makeStyles, Theme } from '@material-ui/core';

import { COLORS } from 'theme/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  linkClass: {
    color: COLORS.white,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default useStyles;
