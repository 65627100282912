import { makeStyles, Theme } from '@material-ui/core';

import { FONT_WEIGHT, SIZES, COLORS } from 'theme/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  navbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  alignEnd: {
    justifyContent: 'flex-end',
  },
  navbarInfo: {
    display: 'flex',
    gap: 50,
  },
  backButton: {
    cursor: 'pointer',
    color: COLORS.darkGray,
    fontSize: SIZES.twentyFour,
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& img': {
      height: 24,
      width: 24,
    },
  },
  userInfo: {
    display: 'flex',
    gap: 15,
  },
  userInitials: {
    width: 45,
    height: 45,
    borderRadius: '50%',
    backgroundColor: COLORS.almostBlack,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: SIZES.twenty,
    fontWeight: FONT_WEIGHT.medium_fiveHundred,
  },
  userNameAndRole: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  name: {
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    fontSize: SIZES.twenty,
  },
  role: {
    color: COLORS.almostBlack,
    fontSize: SIZES.fourteen,
    fontWeight: FONT_WEIGHT.medium_fiveHundred,
  },
  logout: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
}));

export default useStyles;
