import { paths } from 'routes/paths';

const sidebarLinks = [
  {
    text: 'Home',
    to: paths.ROOT,
  },
  {
    text: 'AUM',
    to: paths.AUM,
  },
  {
    text: 'User List',
    to: paths.USERS_LIST,
  },
  {
    text: 'Withdrawals',
    to: paths.WITHDRAWALS,
  },
  {
    text: 'Accounting Reports',
    children: [
      { text: 'Yield Aggregator', to: paths.YIELD_REPORT },
      { text: 'Deposits', to: paths.DEPOSITS_REPORT },
      { text: 'Loans', to: paths.LOANS_REPORT },
    ],
  },
];

// eslint-disable-next-line
export default sidebarLinks;
