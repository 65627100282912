import { makeStyles, Theme } from '@material-ui/core';
import { FONT_WEIGHT, COLORS } from '../../theme/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  buttonClass: {
    color: COLORS.white,
  },

  loginButtonClass: {
    marginTop: theme.spacing(2),
  },

  leftColumn: {
    backgroundColor: COLORS.almostBlack,
    color: COLORS.teal,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  rightColumn: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  faContainer: { display: 'flex', justifyContent: 'center', flexDirection: 'column' },

  appTitle: {
    color: COLORS.teal,
    fontWeight: FONT_WEIGHT.medium_fiveHundred,
  },

  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'start',
    gap: 30,
  },

  paperStyle: {
    padding: theme.spacing(3),
    boxShadow: '3px 17px 54px rgba(0, 0, 0, 0.15) !important',
    borderRadius: '20px !important',
  },

  paperHeading: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    textAlign: 'start',
    gap: 10,
  },

  title: {
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    color: COLORS.almostBlack,
  },

  titleLogin: {
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    color: COLORS.almostBlack,
    textAlign: 'start',
    marginBottom: theme.spacing(3),
  },

  subtitle: { color: COLORS.almostBlack },

  container: {
    backgroundColor: COLORS.bgWhite,
    height: '100vh',
  },

  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: COLORS.red,
    marginBottom: -theme.spacing(3),
    marginTop: theme.spacing(1),
  },

  loginFormFields: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  imageStyle: {
    maxWidth: 200,
    width: '100%',
  },
}));

export default useStyles;
