import { Alert } from 'appTypes';

export enum ActionTypes {
  SET_ALERT = 'SET_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
}

export interface SetAlert {
  type: ActionTypes.SET_ALERT;
  payload: Alert;
}

export interface RemoveAlert {
  type: ActionTypes.REMOVE_ALERT;
  payload: Alert;
}

export type AlertActions = SetAlert | RemoveAlert;
