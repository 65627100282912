import { makeStyles, Theme } from '@material-ui/core';
import { COLORS, FONT_WEIGHT } from 'theme/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  eqCodeContainer: {
    marginTop: theme.spacing(1),
  },
  svg: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(0.5),
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: COLORS.red,
    marginTop: theme.spacing(0),
  },
  
  labelStyle:{
    textAlign:'start',fontWeight:FONT_WEIGHT.semibold_sixHundred
  }
}));

export default useStyles;
