import { Key } from 'react';

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface Alert {
  id: Key;
  alertType: AlertType;
  msg?: string;
}
