import { makeStyles, Theme } from '@material-ui/core';
import { COLORS, FONT_WEIGHT } from 'theme/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  textFied: {
    width: '100%',
    marginBottom: theme.spacing(1.5),
    '& .Mui-error': {
      marginLeft: 0,
    },
  },
  input: {
    width: '100%',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  whiteBackground: {
    backgroundColor: COLORS.white,
  },
  showPasswordIcon: {
    paddingRight: theme.spacing(1),
  },
  closeColor: {
    color: COLORS.red,
  },
  checkColor: {
    color: COLORS.lightGreen,
  },
  visibilityColor: {
    color: COLORS.almostBlack,
  },
  labelStyle: {
    textAlign: 'start',
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    marginBottom: theme.spacing(0.5),
  },
}));

export default useStyles;
