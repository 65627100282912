import { Key } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createAction } from '@reduxjs/toolkit';

import { ActionTypes } from './types';
import { Alert, AlertType } from 'appTypes';
import { AppThunk } from 'store/createStore';

export const setAlertAction = createAction<Alert>(ActionTypes.SET_ALERT);
export const removeAlert = createAction<Key>(ActionTypes.REMOVE_ALERT);

export const setAlert =
  (msg: string, alertType: AlertType): AppThunk =>
  (dispatch) => {
    const id = uuidv4();
    dispatch(setAlertAction({ msg, alertType, id }));
  };
