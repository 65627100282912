import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import CustomButton from 'components/Button/CustomButton';

import logoutIcon from 'assets/images/logout_icon.svg';
import arrowBack from 'assets/images/arrow_back.svg';

import { translate } from 'translations/i18n';
import { logout } from 'store/auth/slice';
import { userDataSelector } from 'store/auth/selectors';
import { paths } from 'routes/paths';

import useStyles from './Navbar.styles';

const role = 'Compliance Officer';

const routesWithBack = [paths.USERS_LIST];

const Navbar = () => {
  //hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const userData = useSelector(userDataSelector);

  const initials = useMemo(() => {
    return `${userData?.firstName[0]}${userData?.lastName[0]}`;
  }, [userData]);

  const entireName = useMemo(() => {
    return `${userData?.firstName} ${userData?.lastName}`;
  }, [userData?.firstName, userData?.lastName]);

  const hasBackButton = useMemo(() => {
    const { pathname } = location;
    const { id } = params;
    const mainPathname = `/${pathname?.split('/')[1]}`;
    return routesWithBack?.includes(mainPathname) && !!id;
  }, [location, params]);

  return (
    <div className={`${classes.navbar} ${!hasBackButton && classes.alignEnd}`}>
      {hasBackButton && (
        <div
          className={classes.backButton}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img alt="arrowBack" src={arrowBack} />
          <span>{translate('general.back')}</span>
        </div>
      )}
      <div className={classes.navbarInfo}>
        <div className={classes.userInfo}>
          <div className={classes.userInitials}>{initials}</div>
          <div className={classes.userNameAndRole}>
            <div className={classes.name}>{entireName}</div>
            <div className={classes.role}>{role}</div>
          </div>
        </div>
        <CustomButton
          text=""
          variant="tertiary"
          size="small"
          onClick={() => {
            console.warn('logout');
            dispatch(logout());
          }}
          jsxElement={
            <div className={classes.logout}>
              {translate('general.logout')}
              <img src={logoutIcon} alt="logout" />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Navbar;
