import { paths } from 'routes/paths';
import { bnb, btc, dai, eqx, eth, usdc, usdt, wbtc } from 'assets/images/icons/tokenIcons';

export const getTokenIcon = (icon: string) => {
  switch (icon) {
    case 'BTC':
      return btc;
    case 'WBTC':
      return wbtc;
    case 'USDT':
      return usdt;
    case 'BNB':
      return bnb;
    case 'EQX':
      return eqx;
    case 'ETH':
    case 'WETH':
    case 'ETH_RINKEBY':
      return eth;
    case 'DAI':
      return dai;
    case 'USDC':
      return usdc;
    case 'HYVE':
      return '';
    case 'BUSD':
      return '';
    default:
      return '';
  }
};

export const getTitleAndSubtitle = (pathname: string) => {
  switch (pathname) {
    case paths?.ROOT:
      return {
        title: 'Welcome User',
        subtitle: 'Here are your week highlights',
      };
    case paths?.AUM:
      return {
        title: 'AUM',
        subtitle: 'Assets Under Management Overview',
      };
    case paths?.USERS_LIST:
      return {
        title: 'Overview',
        subtitle: '',
      };
    case paths?.USERS_LIST_BY_ID:
      return {
        title: 'Username',
        subtitle: 'Subtitle',
      };
    case paths?.WITHDRAWALS:
      return {
        title: '',
        subtitle: 'Withdrawals Approval',
      };
    case paths?.YIELD_REPORT:
      return {
        title: 'Yield Aggregator',
        subtitle: 'Accounting Reports',
      };
    case paths?.DEPOSITS_REPORT:
      return {
        title: 'Deposits',
        subtitle: 'Accounting Reports',
      };
    case paths?.LOANS_REPORT:
      return {
        title: 'Loans',
        subtitle: 'Accounting Reports',
      };
    default:
      return {
        title: 'Welcome User',
        subtitle: 'Here are your week highlights',
      };
  }
};
