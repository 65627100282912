import { makeStyles, Theme } from '@material-ui/core';

import { FONT_FAMILY, FONT_WEIGHT, SIZES, COLORS } from 'theme/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  codeInput: {
    width: '100%',
    '& input': {
      paddingLeft: 0,
      fontSize: SIZES.thirtyTwo,
      fontFamily: FONT_FAMILY.montserratFontText,
      fontWeight: FONT_WEIGHT.medium_fiveHundred,
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),

        [theme.breakpoints.down('xs')]: {
          marginRight: theme.spacing(0.5),
        },
      },
      borderRadius: 5,
      boxSizing: 'border-box',
      textAlign: 'center',
      outline: 'none',
      backgroundColor: COLORS.white,
      height: 60,
      width: 'calc((100% - 50px)/6)',
      border: `1px solid ${COLORS.darkGray}`,
      color: COLORS.darkGray,

      [theme.breakpoints.down('xs')]: {
        height: 50,
        width: 'calc((100% - 25px)/6)',
      },
    },
    '& input::placeholder': {
      opacity: 0.3,
    },
  },
}));

export default useStyles;
