import { CodeInputStyleType, CodeInputType } from 'appTypes';
import { useState, useEffect, CSSProperties } from 'react';
import ReactCodeInput from 'react-code-input';

import { COLORS } from 'theme/fonts';

import styles from './EqCodeInput.module.css';
import useStyles from './EqCodeInput.styles';

const CodeInput = (props: CodeInputType) => {
  //props
  const { fields, onCodeFilled, error, autoFocus = true } = props;

  //state
  const [touched, setTouched] = useState(false);
  const [code, setCode] = useState('');

  //hooks
  const classes = useStyles();

  //lifecycle hooks
  useEffect(() => {
    renderPlaceholders();
  }, []);

  useEffect(() => {
    onCodeFilled!(code.length < fields ? '' : code);
    //eslint-disable-next-line
  }, [code]);

  //methods/functions
  const onChangeHandler = (code: string) => {
    if (!touched) setTouched(true);
    setCode(code);
  };

  const renderPlaceholders = () => {
    const inputs = document.querySelectorAll('.codeInput input');
    inputs.forEach((element: any, index: number) => {
      element.placeholder = index + 1;
    });
  };

  const touchedBlack = touched && COLORS.almostBlack;
  const errorFlag = error && COLORS.red;
  const successFlag = touched && code.length === fields && COLORS.teal;

  const inputStyle: CodeInputStyleType = {
    color: touchedBlack,
    borderColor: successFlag || errorFlag || touchedBlack,
  };

  return (
    <ReactCodeInput
      type="number"
      name="codeInput"
      fields={fields}
      inputMode="numeric"
      inputStyle={inputStyle as CSSProperties}
      className={`${styles.codeInput} ${classes.codeInput} codeInput`}
      onChange={onChangeHandler}
      autoFocus={autoFocus}
    />
  );
};

export default CodeInput;
