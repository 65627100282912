import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookie } from 'utils/cookieHelpers';

export type AuthState = {
  token: string | null;
  isAuthenticated: boolean;
  user: string | null;
  userData: any | null;
  loginError: string | null;
  loginAttemptToken: string;
  codeVerificationNeeded: boolean;
  loading: boolean;
  error?: string;
};

const initialState: AuthState = {
  token: getCookie('token'),
  isAuthenticated: !!getCookie('token'),
  user: null,
  userData: null,
  loginError: null,
  loginAttemptToken: '',
  codeVerificationNeeded: false,
  loading: false,
};

const commonLogout = (state: AuthState) => {
  localStorage.removeItem('token');
  localStorage.removeItem('metamaskId');
  state.token = null;
  state.isAuthenticated = false;
  state.loading = false;
  state.userData = null;
  state.codeVerificationNeeded = false;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(
      state: AuthState,
      action: PayloadAction<{ token: string; username: string; userData: any }>,
    ) {
      state.token = action.payload.token;
      state.user = action.payload.username;
      state.userData = action.payload.userData;
      state.isAuthenticated = true;
    },
    loginFail(state: AuthState, action: PayloadAction<string>) {
      const { payload } = action;
      state.loginError = payload;
      state.user = null;
    },
    request2FA(state, action: PayloadAction<{ username: string; loginAttemptToken: string }>) {
      state.loginAttemptToken = action.payload.loginAttemptToken;
      state.codeVerificationNeeded = true;
      state.userData = {
        ...state.userData,
        username: action.payload.username,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    verifyCodeError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    logout(state) {
      commonLogout(state);
    },
  },
});

export const { loginSuccess, loginFail, request2FA, setLoading, verifyCodeError, logout } =
  authSlice.actions;
export default authSlice.reducer;
