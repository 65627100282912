import { useState, useEffect } from 'react';
import { Button, Tooltip } from '@material-ui/core';

import { CustomButtonType, ConfigButton } from 'appTypes';
import LoadingIndicator from 'components/LoadingIndicator';

import useStyles from './CustomButton.styles';

const primaryConfig: ConfigButton = {
  variant: 'contained',
  color: 'primary',
};

const secondaryConfig: ConfigButton = {
  variant: 'outlined',
  color: 'primary',
};

const tertiaryConfig: ConfigButton = {
  variant: 'outlined',
  color: 'secondary',
};

/**
 * How to use: 
 * <CustomButtonType
    text="text"
    variant="primary"
    size="large"
    leftIcon={getIcon('EQX')}
    rightIcon={getIcon('ETH')}
    to="https://google.com"
    onClick={() => console.log('click handler')}
    />
 * @param props CustomButtonType
 * @returns 
 */
const CustomButton = (props: CustomButtonType) => {
  //props
  const {
    text,
    variant,
    size,
    className,
    to,
    onClick,
    disabled,
    fullWidth,
    tooltipText,
    loading,
    jsxElement,
    ...rest
  } = props;

  //state
  const [buttonConfig, setButtonCofig] = useState<ConfigButton>({} as ConfigButton);

  //hooks
  const classes = useStyles();

  //lifecycle hooks
  useEffect(() => {
    const config = getButtonConfig(variant!);
    setButtonCofig(config);
  }, [variant]);

  //methods
  const getButtonConfig = (variant: string) => {
    switch (variant) {
      case 'primary':
        return primaryConfig;
      case 'secondary':
        return secondaryConfig;
      case 'tertiary':
        return tertiaryConfig;
      default:
        return primaryConfig;
    }
  };

  const getSizeClass = (size: string) => {
    switch (size) {
      case 'large':
        return classes.largeSizeClass;
      case 'medium':
        return classes.mediumSizeClass;
      case 'small':
        return classes.smallSizeClass;
      default:
        return classes.mediumSizeClass;
    }
  };

  const handleOnClick = () => {
    if (!!to) {
      window.open(to, '_blank');
      return;
    }
    onClick!();
  };

  return (
    <Tooltip title={tooltipText || ''}>
      <Button
        onClick={handleOnClick}
        disabled={disabled || loading}
        fullWidth={fullWidth}
        className={`${className} ${getSizeClass(size!)} ${
          variant === 'primary' && classes.buttonClass
        }`}
        {...buttonConfig}
        {...rest}
      >
        {loading ? <LoadingIndicator loading={loading} size={20} /> : text}
        {jsxElement}
      </Button>
    </Tooltip>
  );
};

export default CustomButton;
