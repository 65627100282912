import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    display: 'flex',
  },
  content: {
    marginLeft: 200,
    width: 'calc(100vw - 200px)',
    padding: '40px 50px',
  },
  page: { maxWidth: 1380, margin: '0 auto' },
}));

export default useStyles;
