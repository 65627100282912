export const paths = Object.freeze({
  ROOT: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  AUM: '/aum',
  USERS_LIST: '/users-list',
  USERS_LIST_BY_ID: '/users-list/:id',
  WITHDRAWALS: '/withdrawals',
  YIELD_REPORT: '/yield-report',
  DEPOSITS_REPORT: '/deposits-report',
  LOANS_REPORT: '/loans-report',
});
