import { Grid, Paper, Typography } from '@material-ui/core';

import { translate } from 'translations/i18n';
import eqifiLogo from 'assets/images/eqifi_logo.svg';
import Login from './Login';

import useStyles from './Onboarding.styles';

const Onboarding = () => {
  //hooks
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={4} className={classes.leftColumn}>
        <Grid item xs={8} md={6} lg={5} className={classes.logoContainer}>
          <img alt="eqifi-logo" src={eqifiLogo} className={classes.imageStyle} />
          <Typography variant="h4" className={classes.appTitle}>
            {translate('general.backoffice')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={8} className={classes.rightColumn}>
        <Grid item xs={8} md={6} lg={5} className={classes.faContainer}>
          <Paper className={classes.paperStyle}>
            <Login />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Onboarding;
