import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getIsAuthenticated } from 'store/auth/selectors';
import { paths } from 'routes/paths';
import { getCookie } from 'utils/cookieHelpers';
import { logout } from 'store/auth/slice';

import { EqRouteType } from 'appTypes';

const RouteProtection = (props: EqRouteType) => {
  //props
  const { children } = props;

  // hooks
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(paths.LOGIN);
    } else if (isAuthenticated && !getCookie('token')) {
      dispatch(logout());
    }
    // eslint-disable-next-line
  }, [isAuthenticated, navigate]);

  // add active session / logged flag
  return !!isAuthenticated ? <>{children}</> : <></>;
};

export default RouteProtection;
