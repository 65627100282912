import { lazy } from 'react';

import { EqRouteType } from 'appTypes';
import { paths } from 'routes/paths';

const Dashboard = lazy(() => import('features/Dashboard'));
const Aum = lazy(() => import('features/Aum'));
const UsersList = lazy(() => import('features/UsersList'));
const UserDetails = lazy(() => import('features/UserDetails'));
const Withdrawals = lazy(() => import('features/Withdrawals'));
const YieldReport = lazy(() => import('features/YieldReport'));
const Deposits = lazy(() => import('features/Deposits'));
const Loans = lazy(() => import('features/Loans'));

export const routes: EqRouteType[] = [
  {
    element: <Dashboard />,
    path: paths.ROOT,
    private: true,
  },
  {
    element: <Dashboard />,
    path: paths.DASHBOARD,
    private: true,
  },
  {
    element: <Aum />,
    path: paths.AUM,
    private: true,
  },
  {
    element: <></>,
    path: paths.USERS_LIST,
    private: true,
    nestedRoutes: [
      {
        path: '',
        element: <UsersList />,
      },
      {
        path: ':id',
        element: <UserDetails />,
      },
    ],
  },
  {
    element: <Withdrawals />,
    path: paths.WITHDRAWALS,
    private: true,
  },
  {
    element: <YieldReport />,
    path: paths.YIELD_REPORT,
    private: true,
  },
  {
    element: <Deposits />,
    path: paths.DEPOSITS_REPORT,
    private: true,
  },
  {
    element: <Loans />,
    path: paths.LOANS_REPORT,
    private: true,
  },
];
