import { createReducer } from '@reduxjs/toolkit';

import { Alert } from 'appTypes';
import { removeAlert, setAlertAction } from './actions';

export type AlertState = Alert[];

export const alertInitialState: AlertState = [];

const authReducer = createReducer(alertInitialState, (builder) => {
  builder
    .addCase(setAlertAction, (state, action) => {
      state.push({ ...action.payload });
    })
    .addCase(removeAlert, (state, action) => {
      return state.filter((alert: Alert) => alert.id !== action.payload);
    });
});

export default authReducer;
