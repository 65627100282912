import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';

import Sidebar from 'components/Sidebar';
import Navbar from 'components/Navbar';
import PageInfo from 'components/PageInfo';
import { getTitleAndSubtitle } from 'helpers';

import useStyles from './Layout.styles';

const Layout = () => {
  const [pageInfo, setPageInfo] = useState({ title: '', subtitle: '' });

  // hooks
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    setPageInfo(getTitleAndSubtitle(location?.pathname));
  }, [location]);

  return (
    <Box className={classes.layout}>
      <Sidebar />
      {/* navbar */}
      <Box className={classes.content}>
        <div className={classes.page}>
          <Navbar />
          <PageInfo {...pageInfo} />
          <Outlet />
        </div>
      </Box>
    </Box>
  );
};

export default Layout;
