import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  spinner: {
    width: 50,
    margin: 'auto',
    display: 'block',
  },
}));

export default useStyles;
