import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';

import { translate } from 'translations/i18n';
import Button from 'components/Button/CustomButton';
import Input from 'components/Input';
import { login } from 'store/auth/actions';
import { getCodeVerificationNeeded, getIsAuthenticated } from 'store/auth/selectors';
import TwoFactorAuthentication from '../2FAuthentication';
import { LoginFormValues, LoginInputProps } from 'appTypes';
import { logout } from 'store/auth/slice';

import useStyles from '../Onboarding.styles';

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(translate('onboarding.emailInvalid'))
    .max(255)
    .required(translate('onboarding.emailRequired')),
  password: Yup.string().max(255).required(translate('onboarding.passwordRequired')),
});

const Login = () => {
  //state
  // eslint-disable-next-line
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [tfaScreen, setTfaScreen] = useState(false);

  //hooks
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const codeVerificationNeeded = useSelector(getCodeVerificationNeeded);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();
  const navType = useNavigationType();

  // lifecycle hooks
  useEffect(() => {
    if (codeVerificationNeeded) {
      setTfaScreen(codeVerificationNeeded);
    }
  }, [codeVerificationNeeded]);

  // methods/functions
  const submitCredentials = async (values: LoginFormValues, functions: LoginInputProps) => {
    try {
      functions.setSubmitting(true);
      const { email, password } = values;
      const res = await dispatch(login(email, password));
      if (!!res) {
        setInvalidCredentials(true);
      } else {
        isAuthenticated && navigate('/');
      }
    } catch (error: any) {
      const message = error || translate('general.somethingWentWrong');
      functions.setStatus({ success: false });
      functions.setErrors(message);
    } finally {
      functions.setSubmitting(false);
    }
  };

  isAuthenticated && navigate('/');

  const handleDisableButton = (errors: Object, isSubmitting: boolean, values: LoginFormValues) => {
    const hasErrors = Object.getOwnPropertyNames(errors).length;
    const fieldsFilled = values.email.trim().length > 0 && values.password.trim().length > 0;
    return !!hasErrors || isSubmitting || !fieldsFilled;
  };

  useEffect(() => {
    if (!isAuthenticated && navType === 'POP') {
      dispatch(logout());
      setTfaScreen(false);
    }
  }, [location, dispatch, navType, isAuthenticated]);

  return tfaScreen ? (
    <TwoFactorAuthentication />
  ) : (
    <>
      <Typography variant="h4" className={classes.titleLogin}>
        {translate('onboarding.titleLogin')}
      </Typography>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginValidationSchema}
        onSubmit={submitCredentials}
      >
        {({ handleBlur, handleChange, handleSubmit, values, touched, errors, isSubmitting }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          >
            <Input
              onBlur={handleBlur}
              onChange={handleChange}
              label={translate('onboarding.emailLabel')}
              placeholder={translate('onboarding.emailPlaceholder')}
              value={values.email}
              name="email"
              error={Boolean(touched.email && errors.email)}
              errorMsg={errors.email}
            />
            <Input
              onBlur={handleBlur}
              onChange={handleChange}
              label={translate('onboarding.passwordLabel')}
              placeholder={translate('onboarding.passwordPlaceholder')}
              value={values.password}
              name="password"
              error={Boolean(touched.password && errors.password)}
              errorMsg={errors.password}
              type="password"
            />
            <Button
              text={translate('onboarding.confirm')}
              variant="primary"
              size="large"
              onClick={handleSubmit}
              fullWidth
              disabled={handleDisableButton(errors, isSubmitting, values)}
              className={classes.loginButtonClass}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default Login;
