import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { getCookie } from 'utils/cookieHelpers';

export const HOST = process.env.REACT_APP_API;

export const Endpoints = {
  LOGIN: `/login`,
  GET_USER_BY_ID: (userId: string | number) => `/users/get/${userId}`,
  GET_ALL_USERS: '/users/all',
  GET_ALL_TOKENS: (supportedForTokens: string) =>
    `/tokens/all?supportedForTransactions=${supportedForTokens}`,
  USERS_LIST: '/users/info',
  VALIDATE_2FA_LOGIN: '/2fa/validate-login',
  VALIDATE_2FA_CODE: '/2fa/validate-code',
  WALLET_WITHDRAWALS: '/wallet/withdrawals/detailed',
  APPROVE_WITHDRAWAL: '/wallet/withdrawals/approve',
  REJECT_WITHDRAWAL: '/wallet/withdrawals/reject',
};

export const client = axios.create({
  baseURL: HOST,
});

export const request = <T>(options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse): T => {
    console.debug('Request Successful!', response);
    if (response.data || response.data === 0 || response.data === '0') {
      return response?.data;
    }
    return response as any;
  };

  const onError = (error: AxiosError) => {
    console.error('Request Failed:', error.config);

    if (error.response?.status === 403) {
      console.log('Session expired');
      //TODO logout
      //store.dispatch(logout());

      //TODO Session Expired notification
      //store.dispatch<any>(setAlert(translate('general.sessionExpired'), AlertType.ERROR));
    }

    if (error.response?.status === 401) {
      console.log('Unauthorized');
      //TODO logout
      //store.dispatch(logout());

      //TODO Unauthorized notification
      //store.dispatch<any>(setAlert(translate('general.unauthorized'), AlertType.ERROR));
    }

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error);
  };
  return client({
    ...options,
    headers: {
      //TODO add token
      Authorization: getCookie('token') || '',
      ...(options.headers ?? {}),
    },
  })
    .then(onSuccess)
    .catch(onError);
};

export const getRequest = <T>(url: string, params = {}) => {
  const stringified = JSON.stringify(params);
  const paramsString = Object.keys(params).length ? `?${stringified}` : '';
  return request<T>({
    method: 'get',
    url: `${url}${paramsString}`,
  });
};

export const postRequest = <T>(url: string, body: any = {}) => {
  return request<T>({
    method: 'POST',
    url,
    data: body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
