import { AppDispatch } from '..';
import axios from 'axios';

import { AppThunk } from '../createStore';
import { loginFail, loginSuccess, request2FA, setLoading, verifyCodeError } from './slice';
import { setAlert } from 'store/alert/actions';
import { AlertType } from 'appTypes';
import { validateLogin, validateCode } from 'api/login';
import { Endpoints, HOST } from 'api/api';
import { getUserById } from 'api/userService';
import { translate } from 'translations/i18n';
import { createCookie } from 'utils/cookieHelpers';

export const login =
  (username: string, password: string): AppThunk<Promise<any>> =>
  async (dispatch: AppDispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ username, password });
    const apiLogin = `${HOST}${Endpoints.LOGIN}`;

    try {
      dispatch(setLoading(true));
      const res = (await axios.post(apiLogin, body, config)) as any;

      const token = res.headers.authorization;
      if (token) {
        createCookie('token', token, 23);
        const user = await getUserById(res.data.userId);

        dispatch(loginSuccess({ token, username, userData: user }));
        dispatch(setAlert(translate('general.loggedIn'), AlertType.SUCCESS));
      } else {
        dispatch(request2FA({ username: username, loginAttemptToken: res.data }));
      }

      dispatch(setLoading(false));
    } catch (err: any) {
      dispatch(setAlert('Failed login', AlertType.ERROR));
      dispatch(loginFail('Username or password is empty'));
      dispatch(setLoading(false));

      return err;
    }
  };

export const validate2FALogin =
  (username: string, code: string, loginAttemptToken: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const res = await validateLogin(username, code, loginAttemptToken);
      const token = res.headers.authorization;
      if (token) {
        createCookie('token', token, 23);
        const user = await getUserById(res.data.userId);
        dispatch(setAlert(translate('general.loggedInAfterFA'), AlertType.SUCCESS));
        dispatch(loginSuccess({ token, username, userData: user }));
      }
    } catch (err: any) {
      dispatch(setAlert('Failed 2FA', AlertType.ERROR));

      throw err?.response?.data;
    }
  };

export const validate2FACode =
  (username: string, code: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      await validateCode(username, code);
    } catch (err: any) {
      dispatch(verifyCodeError(err.error));

      throw err?.response?.data;
    }
  };

export const setCodeVerification =
  (status: boolean): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(setCodeVerification(status));
  };
