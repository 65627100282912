import { makeStyles, Theme } from '@material-ui/core';

import { FONT_FAMILY, FONT_WEIGHT, SIZES, COLORS } from 'theme/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    height: '100vh',
    backgroundColor: COLORS.almostBlack,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    position: 'fixed',
  },
  itemLinks: {
    width: 200,
    marginTop: 100,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    fontFamily: FONT_FAMILY.nunitoFontText,
    fontSize: SIZES.eighteen,
  },
  linkSidebar: {
    cursor: 'pointer',
    fontSize: 14,
    textAlign: 'start',
    padding: `0px 25px`,
    '&.active': {
      color: COLORS.teal,
      fontWeight: FONT_WEIGHT.bold_sevenHundred,
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: 10,
      padding: theme.spacing(1, 2),
      margin: theme.spacing(0, 1),
      textAlign: 'start',
    },
  },
  childLinkSidebar: {
    cursor: 'pointer',
    display: 'block',
    textAlign: 'start',
    fontSize: 14,
    marginLeft: 5,
    padding: `5px 5px 5px 15px`,
    margin: theme.spacing(1, 0),
    '&.active': {
      color: COLORS.teal,
      fontWeight: FONT_WEIGHT.semibold_sixHundred,
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: 10,
    },
  },
  multiLevel: {
    padding: `0px 10px`,
  },
  multiLevelOpen: {
    borderLeft: `1px solid ${COLORS.teal}`,
    margin: `0px 10px`,
    paddingTop: 5,
    '&.active': {
      color: COLORS.teal,
      fontWeight: FONT_WEIGHT.bold_sevenHundred,
      borderRadius: 10,
      textAlign: 'start',
    },
  },

  imageContainer: {
    paddingTop: 56,
    cursor: 'pointer',
  },
  listItem: { paddingTop: 0, paddingBottom: 0, fontSize: 14 },
}));

export default useStyles;
