import { makeStyles, Theme } from '@material-ui/core';
import { FONT_FAMILY, FONT_WEIGHT, SIZES, COLORS } from '../../theme/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  buttonClass: {
    color: COLORS.white,
  },
  largeSizeClass: {
    fontFamily: FONT_FAMILY.montserratFontText,
    fontWeight: FONT_WEIGHT.bold_sevenHundred,
    fontSize: SIZES.twenty,
    lineHeight: 1,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    textTransform: 'none',
  },
  mediumSizeClass: {
    fontFamily: FONT_FAMILY.montserratFontText,
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    fontSize: SIZES.eighteen,
    lineHeight: 1,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
  },
  smallSizeClass: {
    fontFamily: FONT_FAMILY.nunitoFontText,
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    fontSize: SIZES.sixteen,
    lineHeight: 1.4,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    textTransform: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
}));

export default useStyles;
