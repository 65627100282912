import { makeStyles } from '@material-ui/core';

import { FONT_WEIGHT, SIZES, COLORS } from 'theme/fonts';

const useStyles = makeStyles(() => ({
  pageInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 10,
    marginBottom: 45,
  },
  titlePage: {
    fontSize: SIZES.thirtyTwo,
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    color: COLORS.almostBlack,
    lineHeight: 1,
  },
  subtitlePage: {
    fontSize: SIZES.eighteen,
    fontWeight: FONT_WEIGHT.semibold_sixHundred,
    color: COLORS.darkGray,
    lineHeight: 1,
  },
}));

export default useStyles;
