import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

import useStyles from './Link.styles';

type LinkProps = {
  to: string;
  children: any;
  className: string;
  onClick?: () => void;
};

const Link = (props: LinkProps) => {
  //props
  const { to, children, className, onClick, ...rest } = props;

  // hooks
  const classes = useStyles();

  return (
    <MuiLink
      className={`${classes.linkClass} ${className}`}
      component={RouterLink}
      to={to}
      onClick={onClick}
      {...rest}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
