export const createCookie = (name: string, value: string, hours?: any) => {
  let expires;
  if (hours) {
    let date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  } else expires = '';
  document.cookie = name + '=' + value + expires + '; path=/';
};

export const getCookie = (name: string) => {
  if (document.cookie === '') return null;
  const data = document.cookie.split(';');
  let cookies: any = {};
  data?.forEach((cookie: any, index) => {
    const [key, value] = cookie.split('=');
    cookies[key?.trim()] = value;
  });
  return cookies[name];
};

export const eraseCookie = (name: string) => {
  createCookie(name, '', -1);
};
