import { createTheme } from '@material-ui/core/styles';

import { FONT_FAMILY, FONT_WEIGHT, SIZES, COLORS } from './fonts';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
    largeMedium: true;
  }
}
// TBD breakpoints
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1282,
      xl: 1920,
      tablet: 1025,
      largeMedium: 1600,
    },
  },
  palette: {
    primary: {
      light: COLORS.white,
      main: COLORS.almostBlack,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.white,
    },
    success: {
      main: COLORS.lightGreen,
    },
    info: {
      main: COLORS.linkBlue,
    },
    error: {
      main: COLORS.red,
    },
  },
  spacing: 10,
  overrides: {
    MuiButton: {
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        color: COLORS.white,
        fontWeight: FONT_WEIGHT.semibold_sixHundred,
        backgroundColor: COLORS.teal,
      },
      outlinedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        color: COLORS.almostBlack,
        borderColor: COLORS.almostBlack,
        fontWeight: FONT_WEIGHT.semibold_sixHundred,
      },
      outlinedSecondary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          border: '1px solid',
          color: COLORS.darkGray,
        },
        color: COLORS.darkGray,
        borderColor: COLORS.darkGray,
        fontWeight: FONT_WEIGHT.semibold_sixHundred,
      },
      root: {
        '&$disabled': {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            border: '1px solid',
            color: COLORS.darkGray,
          },
          cursor: 'not-allowed',
          color: COLORS.darkGray,
          backgroundColor: COLORS.lightGray,
          borderColor: COLORS.darkGray,
          fontWeight: FONT_WEIGHT.semibold_sixHundred,
        },
      },
      textPrimary: {
        color: COLORS.white,
      },
    },
    MuiContainer: {
      root: {
        width: '100%',
        maxWidth: '100% !important',
      },
    },
    MuiInput: {
      root: {
        '& .Mui-error': {
          color: COLORS.teal,
        },
      },
    },
    MuiStepIcon: {
      text: {
        fill: COLORS.teal,
        fontSize: SIZES.sixteen,
        fontWeight: FONT_WEIGHT.bold_sevenHundred,
      },
      root: {
        border: `2px solid ${COLORS.teal}`,
        borderRadius: '50%',
        color: 'white',
        transition: 'none',
      },
      active: {
        border: '2px solid black',
        fill: 'white',
        '& .MuiStepIcon-text': {
          fill: COLORS.black,
        },
      },
      completed: {
        border: 'none',
      },
    },
    MuiTab: {
      root: {
        maxWidth: 'none',
        fontFamily: FONT_FAMILY.montserratFontText,
        lineHeight: '120%',
        fontSize: 20,
        textTransform: 'none',
        '&.Mui-selected': {
          background: 'transparent',
          fontWeight: FONT_WEIGHT.semibold_sixHundred,
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            width: '90%',
            borderBottom: `4px solid ${COLORS.teal}`,
            borderRadius: '5px 6px 0px 0px',
          },
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: 20,
        fontFamily: FONT_FAMILY.robotoFontText,
        fontSize: SIZES.sixteen,
        borderRadius: 5,
        backgroundColor: 'rgba(35, 35, 35, 0.9)',
        fontWeight: FONT_WEIGHT.normal_fourHundred,
      },
    },
    MuiTableRow: {
      root: {
        '&:hover': {
          backgroundColor: `${COLORS.tealTransparent}!important`,
        },
        '&$selected': {
          backgroundColor: `${COLORS.tealTransparent}!important`,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 10,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: COLORS.darkGray,
        '&$checked': {
          color: `${COLORS.almostBlack} !important`,
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 50,
        height: 24,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 50,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(25px)',
          },
        },
      },
      switchBase: {
        padding: 2,
        '&.Mui-checked': {
          transform: 'translateX(25px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#1890ff',
          },
        },
      },
      thumb: {
        color: '#fff',
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 20,
        height: 20,
        borderRadius: 10,
        transition: 'width 2s',
      },
      track: {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: COLORS.darkGrayTransparentZeroTwentyFive,
        boxSizing: 'border-box',
        '$checked$checked + &': {
          opacity: 1,
          backgroundColor: COLORS.teal,
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: COLORS.red,
      },
    },
  },
  typography: {
    fontFamily: ['Raleway', 'Montserrat', 'Open Sans', 'Roboto', 'Nunito'].join(','),
    h1: {
      fontSize: SIZES.fourtyEight,
      fontFamily: FONT_FAMILY.montserratFontText,
      fontWeight: FONT_WEIGHT.medium_fiveHundred,
      color: COLORS.almostBlack,
    },
    h2: {
      fontSize: SIZES.twentyEight,
      fontFamily: FONT_FAMILY.montserratFontText,
      fontWeight: FONT_WEIGHT.semibold_sixHundred,
      color: COLORS.almostBlack,
    },
    h3: {
      fontSize: SIZES.twentyFour,
      fontFamily: FONT_FAMILY.montserratFontText,
      fontWeight: FONT_WEIGHT.medium_fiveHundred,
      color: COLORS.almostBlack,
    },
    h4: {
      fontSize: SIZES.twenty,
      fontFamily: FONT_FAMILY.montserratFontText,
      fontWeight: FONT_WEIGHT.semibold_sixHundred,
      color: COLORS.almostBlack,
    },
    h5: {
      fontSize: SIZES.sixteen,
      fontFamily: FONT_FAMILY.nunitoFontText,
      fontWeight: FONT_WEIGHT.medium_fiveHundred,
      color: COLORS.almostBlack,
    },
    h6: {
      fontSize: SIZES.fourteen,
      fontFamily: FONT_FAMILY.nunitoFontText,
      fontWeight: FONT_WEIGHT.normal_fourHundred,
      color: COLORS.almostBlack,
    },
    subtitle1: {
      fontSize: SIZES.sixteen,
      fontFamily: FONT_FAMILY.nunitoFontText,
      fontWeight: FONT_WEIGHT.bold_sevenHundred,
      color: COLORS.almostBlack,
      lineHeight: '140%',
    },
    subtitle2: {
      fontSize: SIZES.eighteen,
      fontFamily: FONT_FAMILY.robotoFontText,
      fontWeight: FONT_WEIGHT.medium_fiveHundred,
      color: COLORS.almostBlack,
    },
    body1: {
      fontSize: SIZES.fourteen,
      fontFamily: FONT_FAMILY.montserratFontText,
      fontWeight: FONT_WEIGHT.medium_fiveHundred,
      color: COLORS.almostBlack,
    },
    body2: {
      fontSize: SIZES.twelve,
      fontFamily: FONT_FAMILY.robotoFontText,
      fontWeight: FONT_WEIGHT.normal_fourHundred,
      color: COLORS.almostBlack,
    },
  },
  zIndex: {
    drawer: 1100,
    modal: 1400,
    snackbar: 1500,
    tooltip: 1500,
  },
});

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.thirty,
  },
};
theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.twentyTwo,
  },
};
theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.twenty,
  },
};
theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.eighteen,
  },
};
theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.sixteen,
  },
};
theme.typography.h6 = {
  ...theme.typography.h6,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.fourteen,
  },
};
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.sixteen,
  },
};
theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.sixteen,
  },
};
theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.fourteen,
  },
};
theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.down('xs')]: {
    fontSize: SIZES.twelve,
  },
};

export default theme;
