import useStyles from './PageInfo.styles';

import { PageInfoType } from 'appTypes';

const PageInfo = (props: PageInfoType) => {
  //props
  const { title, subtitle } = props;

  //hooks
  const classes = useStyles();

  return (
    <div className={classes.pageInfo}>
      <div className={classes.titlePage}>{title}</div>
      <div className={classes.subtitlePage}>{subtitle}</div>
    </div>
  );
};

export default PageInfo;
